import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { Button, TextInput } from 'react-native-paper';
import OriginalColorsList from '../../components/OriginalColorsList';
import { ColorListItemProps } from '../../types';

const ColorListItem: React.FC<ColorListItemProps> = ({
  id,
  colors,
  onAddColor,
  onRemoveColor,
  isEditing,
  setEditing,
}) => {
  const [newColor, setNewColor] = useState<string>('');

  const handleAddColor = () => {
    if (/^#([0-9A-F]{3}){1,2}$/i.test(newColor)) {
      onAddColor(id, newColor.toUpperCase());
      setNewColor('');
    } else {
      alert('Please enter a valid hex color code.');
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.listsContainer}>
        <OriginalColorsList
          colors={colors}
          onRemoveColor={(color) => onRemoveColor(id, color)}
          isEditing={isEditing}
        />
      </View>

      {isEditing ? (
        <View style={styles.inputContainer}>
          <View style={styles.textInputContainer}>
            <TextInput
              label='Add Color (#hex)'
              value={newColor}
              onChangeText={setNewColor}
              style={styles.input}
              mode='outlined'
            />
            <Button
              onPress={handleAddColor}
              style={styles.addButton}
              mode='contained'
            >
              Add
            </Button>
          </View>
          <Button
            onPress={() => setEditing(false)}
            style={styles.doneButton}
            mode='contained'
            icon='check'
          >
            Done
          </Button>
        </View>
      ) : (
        <Button
          onPress={() => setEditing(true)}
          mode='contained'
          style={styles.editButton}
        >
          Edit Colors
        </Button>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingBottom: 20,
  },
  listsContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
  },
  inputContainer: {
    flexDirection: 'column',
    marginTop: 15,
    alignItems: 'center',
  },
  textInputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '80%',
    maxWidth: 300,
  },
  input: {
    flex: 1,
    marginBottom: 10,
    marginRight: 10,
  },
  addButton: {
    marginTop: 10,
  },
  doneButton: {
    marginTop: 20,
    width: '80%',
  },
  editButton: {
    marginTop: 20,
  },
});

export default ColorListItem;
