import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import ColorItem from './ColorItem';
import { SortedColorsListProps } from '../types';

const SortedColorsList: React.FC<SortedColorsListProps> = ({
  colors,
  originalColors,
  isEditing,
}) => {
  return (
    <View style={styles.container}>
      <Text style={styles.header}>Sorted Colors:</Text>
      {colors.map((color) => {
        const originalIndex = originalColors.indexOf(color);
        return (
          <ColorItem
            key={originalIndex}
            index={originalIndex}
            color={color}
            highlight={originalIndex !== colors.indexOf(color)}
            isEditing={isEditing}
          />
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginVertical: 10,
  },
  header: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 10,
  },
});

export default SortedColorsList;
