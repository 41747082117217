import React from 'react';
import { View, StyleSheet, ScrollView, SafeAreaView } from 'react-native';

interface BaseScreenProps {
  children: React.ReactNode;
}

const BaseScreen: React.FC<BaseScreenProps> = ({ children }) => {
  return (
    <SafeAreaView style={styles.safeArea}>
      <ScrollView contentContainerStyle={styles.scrollViewContent}>
        <View style={styles.container}>{children}</View>
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    paddingTop: 10,
  },
  scrollViewContent: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 16,
    paddingBottom: 20,
  },
  container: {
    flex: 1,
    maxWidth: 600,
    width: '100%',
    padding: 20,
  },
});

export default BaseScreen;
