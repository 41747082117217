import React from 'react';
import { View, Text, StyleSheet, ActivityIndicator } from 'react-native';
import { Card, Button } from 'react-native-paper';
import { StackScreenProps } from '@react-navigation/stack';
import { StackParamList } from '../../types';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import BaseScreen from '../../components/BaseScreen';
import OriginalColorsList from '../../components/OriginalColorsList';
import SortedColorsList from '../../components/SortedColorsList';
import AnimatedCheckmark from './AnimatedCheckmark';

type Props = StackScreenProps<StackParamList, 'SuccessScreen'>;

const SuccessScreen: React.FC<Props> = ({ route, navigation }) => {
  const { listId } = route.params;

  // Find the specific list by `listId`
  const list = useSelector((state: RootState) =>
    state.colorLists.colorLists.find((list) => list.id === listId)
  );

  // If the list is not found, show an error
  if (!list) {
    navigation.navigate('ErrorScreen');
  }

  const { colors: originalColors, organizedColors, loading, error } = list;

  return (
    <BaseScreen>
      <Card style={styles.mainCard}>
        <View style={styles.headerContainer}>
          <AnimatedCheckmark />
          <Text style={styles.successText}>Success!</Text>
        </View>

        {loading ? (
          <ActivityIndicator size='large' color='#4CAF50' />
        ) : error ? (
          <Text style={styles.errorText}>Error: {error}</Text>
        ) : (
          <View style={styles.colors}>
            <OriginalColorsList
              colors={originalColors}
              isEditing={false}
              onRemoveColor={() => {}}
            />
            <SortedColorsList
              colors={organizedColors}
              originalColors={originalColors}
              isEditing={false}
            />
          </View>
        )}
      </Card>
      <View style={styles.buttonContainer}>
        <Button
          onPress={() => navigation.navigate('ColorFlow')}
          style={styles.homeButton}
          mode='contained'
        >
          Go to main page
        </Button>
      </View>
    </BaseScreen>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  mainCard: {
    flex: 1,
    marginVertical: 10,
    borderRadius: 16,
    backgroundColor: '#ffffff',
    shadowColor: '#000',
    shadowOpacity: 0.1,
    shadowRadius: 4,
    shadowOffset: { width: 0, height: 1 },
    elevation: 3,
    padding: 20,
  },
  headerContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 20,
  },
  colors: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  successText: {
    color: '#4CAF50',
    fontSize: 32,
    fontWeight: 'bold',
    marginTop: 20,
  },
  errorText: {
    fontSize: 18,
    color: 'red',
    marginBottom: 20,
    textAlign: 'center',
  },
  buttonContainer: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingHorizontal: 16,
    paddingBottom: 20, // Ensure proper spacing at the bottom
  },
  homeButton: {
    width: '80%',
    maxWidth: 300,
  },
});

export default SuccessScreen;
