import React from 'react';
import { View, StyleSheet, Dimensions } from 'react-native';
import Animated, {
  useSharedValue,
  useAnimatedStyle,
  withTiming,
  withSpring,
  withDelay,
} from 'react-native-reanimated';
import Svg, { Path } from 'react-native-svg';

const { width } = Dimensions.get('window');

const AnimatedCheckmark: React.FC = () => {
  const scale = useSharedValue(0);
  const opacity = useSharedValue(0);

  React.useEffect(() => {
    // Start the animation
    scale.value = withSpring(1, { damping: 10, stiffness: 100 });
    opacity.value = withDelay(300, withTiming(1, { duration: 500 }));
  }, []);

  const animatedStyle = useAnimatedStyle(() => ({
    transform: [{ scale: scale.value }],
    opacity: opacity.value,
  }));

  return (
    <View style={styles.container}>
      <Animated.View style={[styles.checkContainer, animatedStyle]}>
        <Svg
          width={(width * 0.3) / 4} // 1/4 of the original size
          height={(width * 0.3) / 4}
          viewBox='0 0 24 24'
          fill='none'
        >
          <Path
            d='M20 6L9 17L4 12'
            stroke='green'
            strokeWidth={2} // Adjusted for smaller size
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </Svg>
      </Animated.View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
  },
  checkContainer: {
    backgroundColor: '#e0ffe0',
    borderRadius: 100, // Ensures the container is circular
    padding: 10, // Reduced padding for smaller size
  },
});

export default AnimatedCheckmark;
