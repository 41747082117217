import React from 'react';
import { View, Image, StyleSheet } from 'react-native';
import { IconButton } from 'react-native-paper';
import ColorList from './ColorList';
import ListInputForm from './ListInputForm';
import { useDispatch } from 'react-redux';
import { addList } from '../../store/colorListsSlice';
import BaseScreen from '../../components/BaseScreen';

const ColorFlow: React.FC = () => {
  const dispatch = useDispatch();
  const [editMode, setEditMode] = React.useState<boolean>(false);

  // Toggle edit mode globally
  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  // Add a new color list
  const addNewList = (newName: string, sortingType: string) => {
    if (newName.trim() !== '') {
      dispatch(addList({ name: newName, sortingType }));
    }
  };

  return (
    <BaseScreen>
      <Image
        source={require('../../assets/logo-colorflow-gray-bg.png')}
        style={styles.logo}
        resizeMode='contain'
      />
      <View style={styles.editButtonContainer}>
        <View style={styles.editButton}>
          <IconButton
            icon={editMode ? 'close' : 'pencil'}
            iconColor='white'
            size={18}
            onPress={toggleEditMode}
          />
        </View>
      </View>

      <ListInputForm
        open={editMode}
        setOpenEditForm={toggleEditMode}
        addNewList={addNewList}
      />

      <ColorList editMode={editMode} />
    </BaseScreen>
  );
};

const styles = StyleSheet.create({
  logo: {
    width: '100%',
    height: 120,
    alignSelf: 'center',
    marginBottom: 20,
  },
  editButtonContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginBottom: 20,
  },
  editButton: {
    backgroundColor: '#3498db',
    borderRadius: 8,
  },
});

export default ColorFlow;
