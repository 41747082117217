import React, { useEffect } from 'react';
import { View, Text, StyleSheet, Platform } from 'react-native';
import { StackScreenProps } from '@react-navigation/stack';
import { ActivityIndicator } from 'react-native-paper';
import { AdMobInterstitial } from 'expo-ads-admob';
import { StackParamList } from '../../types';
import AdSenseIframe from './AdSenseIframe';

type Props = StackScreenProps<StackParamList, 'FullScreenAd'>;

const FullScreenAd: React.FC<Props> = ({ route, navigation }) => {
  const { listId } = route.params;

  const adUnitId = __DEV__
    ? 'ca-app-pub-3940256099942544/1033173712' // Test Ad Unit ID
    : 'ca-app-pub-9531437442780053~5293423427';

  const handleAdViewed = () => {
    navigation.navigate('SuccessScreen', { listId });
  };
  useEffect(() => {
    if (Platform.OS !== 'web') {
      const loadAdMobAd = async () => {
        try {
          AdMobInterstitial.setAdUnitID(adUnitId);
          AdMobInterstitial.addEventListener('interstitialDidClose', () => {
            navigation.navigate('SuccessScreen', { listId });
          });

          await AdMobInterstitial.requestAdAsync({
            servePersonalizedAds: true,
          });
          await AdMobInterstitial.showAdAsync();
          navigation.navigate('SuccessScreen', { listId }); // Navigate after ad
        } catch (error) {
          console.error('Error loading or showing interstitial ad:', error);
          navigation.goBack(); // Fallback
        }
      };

      loadAdMobAd();
    }
  }, [adUnitId, navigation, listId]);

  return (
    <View style={styles.container}>
      {Platform.OS === 'web' ? (
        <View style={styles.contentContainer}>
          <AdSenseIframe
            client='ca-pub-9531437442780053'
            slot='5314175549'
            style={{ minWidth: '320px', minHeight: '250px' }}
            onAdViewed={handleAdViewed}
          />
        </View>
      ) : (
        <View style={styles.contentContainer}>
          <ActivityIndicator size='large' animating={true} color='#4CAF50' />
          <Text style={styles.loadingText}>Loading Ad...</Text>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  contentContainer: {
    width: '100%',
    maxWidth: 400,
    alignItems: 'center',
  },
  title: {
    fontSize: 18,
    marginBottom: 20,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  loadingText: {
    fontSize: 16,
    marginTop: 10,
    textAlign: 'center',
  },
});

export default FullScreenAd;
