import axios from 'axios';

const OPENAI_API_KEY =
  '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';
const OPENAI_API_URL = 'https://api.openai.com/v1/chat/completions';

export const organizeColors = async (colors: string[]): Promise<string[]> => {
  try {
    const prompt = `Using only these hex colors: ${colors.join(', ')}, sort them by light to dark according to this updated color order: white, cream, pink, red (e.g., #CC6666), orange, yellow, green, blue, indigo, violet, beige, brown, gray, black. Return only the sorted hex codes as a comma-separated list, with no additional text or explanation.`;

    const response = await axios.post(
      OPENAI_API_URL,
      {
        model: 'gpt-4o',
        messages: [{ role: 'user', content: prompt }],
        max_tokens: 2500,
        temperature: 1,
      },
      {
        headers: {
          Authorization: `Bearer ${OPENAI_API_KEY}`,
          'Content-Type': 'application/json',
        },
      }
    );

    console.log(response.data.choices[0].message.content);

    // Assuming the API response contains the organized colors as a comma-separated list in text format
    const organizedColors = response.data.choices[0].message.content
      .trim()
      .split(', ');

    return organizedColors;
  } catch (error) {
    console.error('Error organizing colors:', error);
    throw error;
  }
};
