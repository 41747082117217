import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { organizeColors } from '../utils/api';
import { ColorListType } from '../types';

interface ColorListsState {
  colorLists: ColorListType[];
}

const initialState: ColorListsState = {
  colorLists: [
    {
      id: 1,
      name: 'Closet',
      sortingType: 'marieKondo',
      colors: ['#32a852', '#a83268', '#7d32a8', '#f2ef3a'],
      organizedColors: [],
      loading: false,
      error: '',
    },
    {
      id: 2,
      name: 'Books',
      sortingType: 'brightness',
      colors: ['#2ECC71', '#3498DB', '#1ABC9C'],
      organizedColors: [],
      loading: false,
      error: '',
    },
    {
      id: 3,
      name: 'Pencils',
      sortingType: 'brightness',
      colors: [
        '#FFFFFF',
        '#F5F5DC',
        '#D3D3D3',
        '#C0C0C0',
        '#808080',
        '#FFC0CB',
        '#FF0000',
        '#800000',
        '#FFA500',
        '#FFFF00',
        '#FFD700',
        '#90EE90',
        '#008000',
        '#006400',
        '#00FFFF',
        '#ADD8E6',
        '#0000FF',
        '#00008B',
        '#E6E6FA',
        '#800080',
        '#FF00FF',
        '#D2B48C',
        '#8B4513',
        '#000000',
      ],
      organizedColors: [],
      loading: false,
      error: '',
    },
    {
      id: 4,
      name: 'App',
      sortingType: 'brightness',
      colors: [
        '#CC6666',
        '#D4A165',
        '#D9D275',
        '#7BBF7B',
        '#6B93D6',
        '#8B74D9',
        '#B582D9',
      ],
      organizedColors: [],
      loading: false,
      error: '',
    },
  ],
};

// Async thunk to call the API for organizing colors
export const organizeColorsAsync = createAsyncThunk(
  'colorLists/organizeColors',
  async (
    { listId, colors }: { listId: number; colors: string[] },
    { rejectWithValue }
  ) => {
    try {
      const organizedColors = await organizeColors(colors);
      return { listId, organizedColors };
    } catch (error) {
      return rejectWithValue({ listId, error: 'Failed to organize colors' });
    }
  }
);

const colorListsSlice = createSlice({
  name: 'colorLists',
  initialState,
  reducers: {
    addList: (
      state,
      action: PayloadAction<{ name: string; sortingType: string }>
    ) => {
      const newList: ColorListType = {
        id: state.colorLists.length + 1,
        name: action.payload.name,
        sortingType: action.payload.sortingType,
        colors: [],
        organizedColors: [],
        loading: false,
        error: '',
      };
      state.colorLists.push(newList);
    },
    addColorToList: (
      state,
      action: PayloadAction<{ id: number; color: string }>
    ) => {
      const list = state.colorLists.find(
        (list) => list.id === action.payload.id
      );
      if (list) list.colors.push(action.payload.color);
    },
    removeColorFromList: (
      state,
      action: PayloadAction<{ id: number; color: string }>
    ) => {
      const list = state.colorLists.find(
        (list) => list.id === action.payload.id
      );
      if (list)
        list.colors = list.colors.filter((c) => c !== action.payload.color);
    },
    editListName: (
      state,
      action: PayloadAction<{ id: number; name: string }>
    ) => {
      const list = state.colorLists.find(
        (list) => list.id === action.payload.id
      );
      if (list) list.name = action.payload.name;
    },
    removeList: (state, action: PayloadAction<number>) => {
      state.colorLists = state.colorLists.filter(
        (list) => list.id !== action.payload
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(organizeColorsAsync.pending, (state, action) => {
        const list = state.colorLists.find(
          (list) => list.id === action.meta.arg.listId
        );
        if (list) {
          list.loading = true;
          list.error = '';
        }
      })
      .addCase(organizeColorsAsync.fulfilled, (state, action) => {
        const { listId, organizedColors } = action.payload;
        const list = state.colorLists.find((list) => list.id === listId);
        if (list) {
          list.organizedColors = organizedColors;
          list.loading = false;
        }
      })
      .addCase(organizeColorsAsync.rejected, (state, action) => {
        const { listId, error } = action.payload as {
          listId: number;
          error: string;
        };
        const list = state.colorLists.find((list) => list.id === listId);
        if (list) {
          list.loading = false;
          list.error = error;
        }
      });
  },
});

export const {
  addList,
  addColorToList,
  removeColorFromList,
  editListName,
  removeList,
} = colorListsSlice.actions;

export default colorListsSlice.reducer;
