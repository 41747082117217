import React from 'react';
import { View, StyleSheet } from 'react-native';
import { TextInput, Button, useTheme, HelperText } from 'react-native-paper';
import { Formik } from 'formik';
import * as Yup from 'yup';

interface EditFormProps {
  open: boolean;
  setOpenEditForm: (open: boolean) => void;
  addNewList: (newName: string, sortingType: string) => void;
}

// Form validation schema using Yup
const validationSchema = Yup.object({
  listName: Yup.string().required('List name is required'),
  //sortingType: Yup.string().required("Please select a sorting type"),
});

const ListInputForm: React.FC<EditFormProps> = ({ open, addNewList }) => {
  const theme = useTheme();

  return (
    <View style={[styles.container, { display: open ? 'flex' : 'none' }]}>
      <Formik
        initialValues={{ listName: '', sortingType: '' }}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          addNewList(values.listName, values.sortingType);
          resetForm();
        }}
      >
        {({
          handleChange,
          handleSubmit,
          values,
          errors,
          touched,
          setFieldValue,
        }) => (
          <View>
            {/* List Name Input */}
            <TextInput
              label='New List Name'
              value={values.listName}
              onChangeText={handleChange('listName')}
              error={Boolean(touched.listName && errors.listName)}
              mode='outlined'
              style={styles.input}
            />
            <HelperText
              type='error'
              visible={!!errors.listName && touched.listName}
            >
              {errors.listName}
            </HelperText>

            {/* Sorting Options with Tooltips */}
            {/*  <RadioButton.Group
              onValueChange={(value) => setFieldValue("sortingType", value)}
              value={values.sortingType}
            >
              <View style={styles.radioOption}>
                <RadioButton value="brightness" />
                <Text style={styles.radioLabel}>Sort by Brightness</Text>
                <IconButton
                  icon={() => (
                    <FontAwesome
                      name="question-circle"
                      size={20}
                      color={theme.colors.onSurface}
                    />
                  )}
                  onPress={() => alert("Sort by lightest to darkest")}
                />
              </View>
              <View style={styles.radioOption}>
                <RadioButton value="marieKondo" />
                <Text style={styles.radioLabel}>Marie Kondo Style</Text>
                <IconButton
                  icon={() => (
                    <FontAwesome
                      name="question-circle"
                      size={20}
                      color={theme.colors.onSurface}
                    />
                  )}
                  onPress={() => alert("Sort by what sparks joy")}
                />
              </View>
            </RadioButton.Group>
            <HelperText
              type="error"
              visible={!!errors.sortingType && touched.sortingType}
            >
              {errors.sortingType}
            </HelperText>
 */}
            {/* Add Button */}
            <Button
              mode='contained'
              onPress={() => handleSubmit()}
              style={styles.button}
              icon='plus'
            >
              Add List
            </Button>
          </View>
        )}
      </Formik>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 16,
    borderWidth: 1,
    borderColor: '#ccc',
    padding: 16,
    borderRadius: 8,
    backgroundColor: 'white',
  },
  input: {
    marginBottom: 8,
  },
  radioOption: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 8,
  },
  radioLabel: {
    marginLeft: 8,
    flex: 1,
  },
  button: {
    marginTop: 16,
  },
});

export default ListInputForm;
