import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Button, ActivityIndicator, Text } from 'react-native-paper';

interface AdSenseIframeProps {
  client: string;
  slot: string;
  style?: React.CSSProperties;
  onAdViewed?: () => void; // Callback to handle navigation after ad is viewed
}

const AdSenseIframe: React.FC<AdSenseIframeProps> = ({
  client,
  slot,
  style,
  onAdViewed,
}) => {
  const [isAdLoaded, setIsAdLoaded] = useState(false);

  const iframeSrc = `https://googleads.g.doubleclick.net/pagead/ads?client=${client}&slot=${slot}&format=auto&output=html&h=250&border=0`;

  useEffect(() => {
    // Simulate ad loading
    const adLoadTimeout = setTimeout(() => {
      setIsAdLoaded(true); // Mark ad as loaded after a delay
    }, 2000);

    return () => {
      clearTimeout(adLoadTimeout); // Cleanup timeout
    };
  }, []);

  return (
    <View style={styles.container}>
      {!isAdLoaded && (
        <View style={styles.loadingContainer}>
          <ActivityIndicator size='large' animating />
          <Text style={styles.loadingText}>Loading Ad...</Text>
        </View>
      )}
      {isAdLoaded && (
        <View style={styles.adContainer}>
          <iframe
            title='AdSense Ad'
            src={iframeSrc}
            style={{
              width: '100%',
              height: '250px',
              border: 'none',
              overflow: 'hidden',
              backgroundColor: '#f1f1f1', // Debug background
              ...style,
            }}
            scrolling='no'
          />
          <Button
            onPress={onAdViewed}
            style={styles.nextButton}
            mode='contained'
          >
            Next
          </Button>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
    backgroundColor: '#fff',
  },
  loadingContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingText: {
    marginTop: 10,
    fontSize: 16,
    textAlign: 'center',
  },
  adContainer: {
    width: '100%',
    maxWidth: 600,
    alignItems: 'center',
  },
  nextButton: {
    marginTop: 20,
    width: '80%',
    maxWidth: 300,
    justifyContent: 'center',
  },
});

export default AdSenseIframe;
