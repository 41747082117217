import React, { useEffect } from 'react';
import { View, StyleSheet, Text, TouchableOpacity } from 'react-native';
import { Card, IconButton, Button } from 'react-native-paper';
import { useNavigation, RouteProp, useRoute } from '@react-navigation/native';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import {
  addColorToList,
  removeColorFromList,
  organizeColorsAsync,
} from '../../store/colorListsSlice';
import { StackNavigationProp } from '@react-navigation/stack';
import { StackParamList } from '../../types';
import BaseScreen from '../../components/BaseScreen';
import ColorListItem from './ColorListItem';

type ColorListDetailsRouteProp = RouteProp<
  { params: { listId: number } },
  'params'
>;

type NavigationProp = StackNavigationProp<StackParamList, 'ColorListDetails'>;

const ColorListDetails: React.FC = () => {
  const route = useRoute<ColorListDetailsRouteProp>();
  const navigation = useNavigation<NavigationProp>();
  const dispatch = useDispatch<AppDispatch>();
  const { listId } = route.params;

  const list = useSelector((state: RootState) =>
    state.colorLists.colorLists.find((list) => list.id === listId)
  );

  const [isEditing, setIsEditing] = React.useState(false);

  const handleGoBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate('ColorFlow');
    }
  };

  useEffect(() => {
    if (!listId || !list) {
      navigation.navigate('ErrorScreen');
    }
  }, [listId, list, navigation]);

  if (!list) return null;

  const handleOrganize = () => {
    dispatch(organizeColorsAsync({ listId, colors: list.colors }));
    navigation.navigate('FullScreenAd', { listId });
  };

  return (
    <BaseScreen>
      <Card style={styles.mainCard}>
        <View style={styles.headerContainer}>
          <TouchableOpacity
            onPress={handleGoBack}
            style={styles.backButtonContainer}
          >
            <IconButton icon='arrow-left' size={24} style={styles.backButton} />
          </TouchableOpacity>
          <Text style={styles.header}>{list.name}</Text>
        </View>
        <ColorListItem
          id={list.id}
          colors={list.colors}
          onAddColor={(id, color) => dispatch(addColorToList({ id, color }))}
          onRemoveColor={(id, color) =>
            dispatch(removeColorFromList({ id, color }))
          }
          isEditing={isEditing}
          setEditing={setIsEditing}
        />
      </Card>
      {!isEditing && (
        <View style={styles.buttonContainer}>
          <Button
            mode='contained'
            style={styles.organizeButton}
            onPress={handleOrganize}
          >
            Organize
          </Button>
        </View>
      )}
    </BaseScreen>
  );
};

const styles = StyleSheet.create({
  mainCard: {
    flex: 1,
    marginVertical: 10,
    borderRadius: 16,
    backgroundColor: '#ffffff',
    shadowColor: '#000',
    shadowOpacity: 0.1,
    shadowRadius: 4,
    shadowOffset: { width: 0, height: 1 },
    elevation: 3,
    padding: 20,
  },
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
    position: 'relative',
  },
  backButtonContainer: {
    position: 'absolute',
    left: 0,
    zIndex: 10,
  },
  backButton: {
    margin: 0,
  },
  header: {
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
    flex: 1,
  },
  buttonContainer: {
    paddingHorizontal: 16,
    paddingBottom: 20,
    alignItems: 'center',
  },
  organizeButton: {
    width: '80%',
    maxWidth: 300,
  },
});

export default ColorListDetails;
