import React from 'react';
import { NavigationContainer, DefaultTheme } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import ColorFlow from '../screens/ColorFlow/ColorFlow';
import ColorListDetails from '../screens/ColorListDetails/ColorListDetails';
import FullScreenAd from '../screens/FullScreenAd/FullScreenAd';
import SuccessScreen from '../screens/SuccessScreen/SucessScreen';
import ErrorScreen from '../screens/ErrorScreen';
import { StackParamList } from '../types';

const Stack = createNativeStackNavigator<StackParamList>();

// Linking configuration
const linking = {
  prefixes: ['exp://'], // Update this with your app's URL if deployed
  config: {
    screens: {
      ColorFlow: '/',
      ColorListDetails: '/details',
      FullScreenAd: '/ad',
      SuccessScreen: '/success',
      ErrorScreen: '/error',
    },
  },
};

const AppNavigator: React.FC = () => {
  return (
    <NavigationContainer linking={linking} theme={DefaultTheme}>
      <Stack.Navigator
        initialRouteName='ColorFlow'
        screenOptions={{ headerShown: false }}
      >
        <Stack.Screen name='ColorFlow' component={ColorFlow} />
        <Stack.Screen name='ColorListDetails' component={ColorListDetails} />
        <Stack.Screen
          name='FullScreenAd'
          component={FullScreenAd}
          options={{ presentation: 'fullScreenModal' }}
        />
        <Stack.Screen name='SuccessScreen' component={SuccessScreen} />
        <Stack.Screen name='ErrorScreen' component={ErrorScreen} />
      </Stack.Navigator>
    </NavigationContainer>
  );
};

export default AppNavigator;
