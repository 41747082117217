import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import ColorItem from './ColorItem';
import { OriginalColorsListProps } from '../types';

const OriginalColorsList: React.FC<OriginalColorsListProps> = ({
  colors,
  onRemoveColor,
  isEditing,
}) => {
  return (
    <View style={styles.container}>
      <Text style={styles.header}>Colors to organize:</Text>
      {colors.map((color, index) => (
        <ColorItem
          key={index}
          index={index}
          color={color}
          onRemove={() => onRemoveColor(color)}
          isEditing={isEditing}
        />
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginVertical: 10,
  },
  header: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 10,
  },
});

export default OriginalColorsList;
