import React from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { Card, IconButton, useTheme } from 'react-native-paper';
import { ColorListProps, StackParamList } from '../../types';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { removeList } from '../../store/colorListsSlice';
import { FontAwesome } from '@expo/vector-icons';

type NavigationProp = StackNavigationProp<StackParamList, 'ColorListDetails'>;

const ColorList: React.FC<ColorListProps> = ({ editMode }) => {
  const colorLists = useSelector(
    (state: RootState) => state.colorLists.colorLists
  );
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigation = useNavigation<NavigationProp>();

  return (
    <View style={styles.container}>
      {colorLists.map((list) => (
        <Card key={list.id} style={styles.card}>
          <TouchableOpacity
            onPress={() =>
              navigation.navigate('ColorListDetails', {
                listId: list.id,
              })
            }
          >
            <Card.Title
              title={list.name}
              titleStyle={styles.listName}
              right={(props) =>
                editMode && (
                  <IconButton
                    {...props}
                    icon={() => (
                      <FontAwesome
                        name='trash'
                        size={20}
                        color={theme.colors.onSurface}
                      />
                    )}
                    onPress={(e) => {
                      e.stopPropagation(); // Prevent triggering the list toggle
                      dispatch(removeList(list.id));
                    }}
                  />
                )
              }
            />
          </TouchableOpacity>
        </Card>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 20,
  },
  card: {
    marginBottom: 15,
    borderRadius: 12,
    backgroundColor: 'white',
    elevation: 3,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
  },
  listName: {
    fontSize: 18,
    color: '#333',
  },
});

export default ColorList;
