import React from 'react';
import { View, Text, StyleSheet, Platform } from 'react-native';
import { IconButton } from 'react-native-paper';
import { ColorItemProps } from '../types';

const ColorItem: React.FC<ColorItemProps> = ({
  index,
  color,
  onRemove,
  highlight = false,
  isEditing,
}) => {
  return (
    <View style={styles.container}>
      <View style={styles.indexContainer}>
        <Text style={[styles.index, highlight && styles.highlight]}>
          {index + 1}.
        </Text>
      </View>

      <View style={styles.ballContainer}>
        <View style={[styles.ball, { backgroundColor: color }]} />
      </View>
      <Text style={styles.colorHex}>{color}</Text>

      {/* Fixed-size container for the trash icon to avoid jumping */}
      <View style={styles.iconContainer}>
        {isEditing && onRemove && (
          <IconButton
            icon='delete'
            onPress={onRemove}
            size={14}
            iconColor='#fff'
            style={styles.removeButton}
          />
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 40,
    flexDirection: 'row',
    alignItems: 'center',
  },
  indexContainer: {
    width: 25,
    justifyContent: 'center',
  },
  index: {
    color: 'black',
  },
  highlight: {
    color: 'red',
  },
  ballContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 45,
  },
  ball: {
    width: 30,
    height: 30,
    borderRadius: 15,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.3,
    shadowRadius: 6,
    elevation: 10,
    ...(Platform.OS === 'web' && {
      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.3)',
    }),
  },
  colorHex: {
    width: 80,
  },
  iconContainer: {
    width: 24, // Fixed width for the icon container
    height: 24, // Match the icon's height
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 10,
  },
  removeButton: {
    backgroundColor: '#e74c3c',
    borderRadius: 3,
  },
});

export default ColorItem;
